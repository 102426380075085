<template>
    <div class="financials-ervice-page">
        <div class="banner-wrap">
            <div class="banner">
                <div class="banner-box">
                    <div class="banner-text">
                        <div class="title animate__animated animate__fadeInLeftBig">
                            科技金融解决方案
                        </div>
                        <div class="sub-title animate__animated animate__fadeInRightBig">
                            <p>
                                基于“五域四链”数据底座、独创的科创场景模型、科技金融全场景的产品矩阵</br>
                                为金融机构提供科技金融大数据赋能、科技金融产品设计和运营等解决方案</br>
                                促进国内“科技-产业-金融”协同，帮忙金融机构解决“找不到，看不懂，风控难”三大痛点
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="contrast-main animate__animated animate__fadeIn">
            <div class="content">
                <img src="https://images.zzt.com.cn/gnW/2024/04/29/contrast-image.png">
            </div>
        </section>
        <section class="benchmark-cases animate__animated animate__fadeIn">
            <h1 class="title" style="color: #fff;">标杆案例</h1>
            <div class="content">
                <div class="card-main">
                    <div class="card-item hover_effect" v-for="(item, index) in benchmarkCases" :key="index"
                        :style="`background-image: url('${item.bgImage}')`">
                        <p class="card-item-title">{{ item.title }}</p>
                        <div class="card-item-desc">
                            <div class="line-item" v-for="ite in item.desc">
                                <p>{{ ite }}</p>
                            </div>
                        </div>
                        <div class="card-item-tag">
                            <p v-for="its in item.tagList" :key="its">{{ its }}</p>
                        </div>
                        <p class="card-item-keyProducts">{{ item.keyProducts }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="service-form animate__animated animate__fadeIn">
            <h1 class="title">灵活服务形式</h1>
            <div class="content">
                <div class="card-main">
                    <div class="card-item hover_effect" v-for="(item, index) in serviceForm" :key="index"
                        :style="`background-image: url('${item.bgImage}')`">
                        <p class="card-item-title">{{ item.title }}</p>
                        <p class="card-item-desc">{{ item.desc }}</p>
                    </div>
                </div>
            </div>
        </section>
        <trial id="trial" title="解决方案咨询" sceneCode="1" />
    </div>
</template>
<script>
import trial from '@/components/trial.vue'
export default {
    components: { trial },
    data() {
        return {
            benchmarkCases: [
                {
                    title: '科创企业评估',
                    // desc: ['中知数通与中信银行北京分行共同探索科技大数据驱动科创产品与服务创新，共建“科技成果转化贷”、中小微科企信贷产品创新模式。', '在中信银行的客户准入、筛选、预授信打分卡设计等核心环节，赋能中信银行在贷前-贷中-贷后环节高效识别科创企业的技术水平与成长性'],
                    desc: ['中知数通与某股份制银行分行共同探索科技大数据驱动科创产品与服务创新，共建信贷产品创新服务模式。', '在客户准入、筛选、预授信打分卡设计等核心环节，赋能银行贷前-贷中-贷后环节高效识别科创企业的技术水平与成长性。'],
                    tagList: ['底层科创数据构建', '动态模型调整', '准入客群画像', '线上化预评估工具'],
                    keyProducts: '关键产品：深眸、数据服务平台',
                    bgImage: 'https://images.zzt.com.cn/gnW/2024/04/29/case-card-bg1.png'
                },
                {
                    title: '数智赋能',
                    desc: ['中知数通为平安银行科创金融业务提供多样化数智赋能，助力搭建国内首款纯数字化专利贷产品、搭建“企业成长性评估系统”，提供多项科创数据及评价模型，以数智能力作为驱动业务发展引擎和坚实底座'],
                    tagList: ['科创贷系列方案产品设计', '共建产品评分卡', '监测贷中贷后风险', '监管报数及客群管理'],
                    keyProducts: '关键产品：专利评估模型、科技力评估模型、数据服务平台',
                    bgImage: 'https://images.zzt.com.cn/gnW/2024/04/29/case-card-bg2.png'
                }
            ],
            serviceForm: [{
                title: '标准化账号',
                desc: '深眸、瞰见、科技尽调报告等线上产品满足快速把握产业图谱、产业宏观现状、企业科技能力等',
                bgImage: 'https://images.zzt.com.cn/gnW/2024/04/29/service-form-card-bg1.png.png',
            }, {
                title: '模型共建',
                desc: '基于产业、应用场景、金融产品的特色等，协助建立或更新单独的科技企业信用评价模型，持续迭代新的信贷审核模型',
                bgImage: 'https://images.zzt.com.cn/gnW/2024/04/29/service-form-card-bg2.png.png',
            }, {
                title: '定制服务',
                desc: '可定制五大主题域——区域、产业、机构、技术、人才的特色标签；共建模型、产品评分卡等升级到标准产品内',
                bgImage: 'https://images.zzt.com.cn/gnW/2024/04/29/service-form-card-bg3.png.png',
            }, {
                title: '数据服务',
                desc: '根据用户已有的系统，开放接口，支持批量调用，支撑系统模块功能更新；或根据用户数据应用场景，批量定期提供数据包，应用于建模、数据统计分析、项目复盘等',
                bgImage: 'https://images.zzt.com.cn/gnW/2024/04/29/service-form-card-bg4.png.png',
            }],
        }
    },
}
</script>

<style lang="scss" scoped>
section {
    padding-top: Translate(74px);

    .content {
        width: $container-width;
        margin: 0 auto;

        .img-container {
            display: flex;
            justify-content: space-between;

            img {
                margin-left: Translate(20px);
            }
        }
    }

    .title {
        font-size: Translate(36px);
        color: #333333;
        font-weight: 500;
        margin: 0 auto Translate(46px);
        min-width: $container-width;
    }
}

.financials-ervice-page {
    .banner-wrap {
        background-color: #e0edfb;

        .banner {
            width: 100%;
            min-width: $container-width;
            max-width: Translate(1920px);
            height: Translate(500px);
            background: url("https://images.zzt.com.cn/gnW/2024/04/29/financial-service-bg.png") no-repeat;
            background-position: center center;
            background-size: cover;
            margin: 0 auto;

            &-box {
                width: $container-width;
                margin: 0 auto;
                position: relative;
                height: 100%;
            }

            &-text {
                text-align: left;

                .title {
                    position: absolute;
                    top: Translate(170px);
                    left: 0;
                    font-size: Translate(40px);
                    font-weight: 500;
                }

                .sub-title {
                    position: absolute;
                    top: Translate(240px);
                    left: 0;
                    font-size: Translate(16px);
                    color: #666;
                    width: Translate(774px);

                    p {
                        height: Translate(30px);
                        line-height: Translate(30px);
                    }
                }
            }
        }
    }

    .contrast-main {
        width: 100%;
        height: Translate(544px);
        box-sizing: border-box;
        background: #FFFFFF;

        .content {
            display: flex;
            justify-content: center;

            img {
                width: Translate(993px);
                height: Translate(384px);
            }
        }
    }

    .benchmark-cases {
        height: Translate(660px);
        background: url('https://images.zzt.com.cn/gnW/2024/04/29/case-bg.png') no-repeat;
        box-sizing: border-box;
        background-position: center center;
        background-size: cover;

        .content {
            .card-main {
                display: flex;
                justify-content: space-between;

                .card-item {
                    width: Translate(588px);
                    height: Translate(404px);
                    box-sizing: border-box;
                    padding: 0 Translate(30px);
                    text-align: left;
                    background-position: center center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    box-shadow: 0 4px 8px 0 rgba(166,166,166,0.10);

                    .card-item-title {
                        font-size: Translate(24px);
                        color: #333333;
                        text-align: center;
                        line-height: Translate(34px);
                        font-weight: 500;
                        margin-top: Translate(31px);
                        margin-bottom: Translate(8px);
                    }

                    .card-item-desc {
                        height: Translate(165px);

                        .line-item {
                            display: flex;

                            p {
                                font-size: Translate(14px);
                                color: #666666;
                                line-height: Translate(24px);
                                font-weight: 500;
                                margin-bottom: Translate(6px);
                                width: Translate(514px);
                            }

                            &::before {
                                content: '';
                                display: inline-block;
                                width: Translate(4px);
                                height: Translate(4px);
                                border-radius: Translate(2px);
                                background: #687492;
                                margin-right: Translate(10px);
                                position: relative;
                                top: Translate(10px);
                            }
                        }
                    }

                    .card-item-tag {
                        display: flex;
                        flex-wrap: wrap;
                        width: Translate(514px);

                        p {
                            width: Translate(200px);
                            margin-right: Translate(40px);
                            margin-bottom: Translate(8px);
                            font-size: Translate(16px);
                            color: #333333;
                            font-weight: 500;

                            &::before {
                                content: "";
                                display: inline-block;
                                background: url(https://images.zzt.com.cn/gnW/2024/04/28/select-icon.png) no-repeat;
                                width: 1.00053rem;
                                height: 1.00053rem;
                                background-position: center center;
                                background-size: cover;
                                margin-right: Translate(8px);
                            }
                        }
                    }

                    .card-item-keyProducts {
                        font-size: Translate(16px);
                        color: #4088EB;
                        font-weight: 500;
                        margin-top: Translate(45px)
                    }
                }
            }

            .btn-box {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: Translate(42px);
            }
        }

    }

    .service-form {
        height: Translate(484px);
        background-color: #fff;
        box-sizing: border-box;

        .content {
            .card-main {
                display: flex;
                justify-content: space-between;

                .card-item {
                    width: Translate(278px);
                    height: Translate(240px);
                    box-sizing: border-box;
                    padding: 0 Translate(24px);
                    text-align: left;
                    background-position: center center;
                    background-size: cover;
                    background-size: Translate(278px) Translate(240px);
                    background-repeat: no-repeat;
                    box-shadow: 0 0 21px 0 #F1F4FF;

                    .card-item-title {
                        margin-top: Translate(74px);
                        font-size: Translate(18px);
                        color: #333333;
                        font-weight: 500;
                    }

                    .card-item-desc {
                        margin-top: Translate(4px);
                        font-size: Translate(14px);
                        color: #666666;
                        line-height: Translate(24px);
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
</style>